<template>
    <div class="user-content">
        <div class="user-info-title">我的土地


          <el-button type="primary" style="margin-bottom:20px;background: #0960bd;padding: 8px 14px; float: right" @click="formDialogRef.openDialog()">发布土地</el-button>

        </div>

        <div class="user-info-list">

            <el-table :data="data.tableData" style="width: 100%">
                <el-table-column type="index" label="序号" width="80" >
                </el-table-column>

                <el-table-column prop="title" label="地块标题" width="220" show-overflow-tooltip>
                  <template slot-scope="scope" >
                    <RouterLink :to="url + scope.row.id" >
                      {{scope.row.title}}
                    </RouterLink>
                  </template>

                </el-table-column>
                <el-table-column prop="updateTime" label="修改时间" width="180">
                </el-table-column>
                <el-table-column prop="landPurpose" label="土地用途" width="120">
					<template #default="scope">
<!--						{{ findLabel(land_purpose, scope.row.landPurpose) }}-->

            {{ getLandPurposeName( scope.row.landPurpose) }}
					</template>
				</el-table-column>
				<el-table-column prop="auditStatus" label="审核状态" width="140" show-overflow-tooltip>
					<template #default="scope">
						{{ scope.row.auditStatus === 2 ? '审核通过' : scope.row.auditStatus === 3 ? '审核不通过' : '待审核' }}
					</template>
				</el-table-column>


              <el-table-column prop="action" label="操作">
                <template #default="scope">
                  <el-button text type="primary" small  v-if="showComplementary(scope.row)" @click="test(scope.row)">补充资料</el-button>

                </template>
              </el-table-column>
<!--            <el-table-column label="操作" width="180">-->
<!--              <template #default="scope">-->
<!--                <el-button text type="primary"  @click="formDialogRef.openDialog(scope.row.id)">查看详情</el-button>-->
<!--              </template>-->
<!--            </el-table-column>-->
                <!-- <el-table-column prop="action" label="操作">
                </el-table-column> -->
            </el-table>
            <div class="user-info-list-page">
                <el-pagination background style="float: right" layout="total,  prev, pager, next, jumper" :page-count="data.totalPage" :current-page="data.currentPage" @current-change="pageChange">
                </el-pagination>
            </div>
          <form-dialog ref="formDialogRef" @refresh="pageChange" />
          <auction-trade-complementary-files-new-dialog ref="AuctionTradeComplementaryFilesNewDialogRef" @refresh="pageChange" />

        </div>
    </div>
</template>
<script setup>
import { reactive,onMounted,ref,defineAsyncComponent  } from 'vue'
import CommonService from '@/service/CommonService';
import { useDict, findLabel } from "@/hooks/dict";
import {RouterLink} from "vue-router";

const { land_purpose } = useDict('land_purpose')
const formDialog = defineAsyncComponent(() => import('./land_add.vue'));
const formDialogRef = ref();
const data = reactive({
    pageSize:10,
    currentPage:1,
    keyword:'',
    totalPage:1,
    tableData: [
        
    ]
})
const url = '/land/detail/';
const pageChange = (page) =>{
    loadLand(page);
}


const getLandPurposeName= (id)=> {
  let land_purposes = useDict('demand_purpose');
  let tmp = '';
  land_purposes.demand_purpose.value.forEach((item) => {
    if (item.value === id) {
      tmp =  item.label;
    }
  })
  return tmp;
}
const loadLand = (page) =>{
    const userId = localStorage.getItem('user_id');
    CommonService.getLandList({
        createBy:userId,
        current:page
    }).then((res)=>{
        if(res.code === 0){
          console.log(res)
            data.tableData = res.data.records;
            data.totalPage = res.data.pages;
            data.currentPage = res.data.current;
        }
    })
}

const showComplementary = (row) => {
  if(row.transactionStatus=="processing"  && row.transactionId!=null && (row.transactionType=="auction"  || row.transactionType=="listing"))
  {
     return true;
  }else
  {
    return false;
  }

} ;

const AuctionTradeComplementaryFilesNewDialog = defineAsyncComponent(() => import('@/components/AuctionTradeComplementaryFilesNew.vue'));
const AuctionTradeComplementaryFilesNewDialogRef = ref();

const test = (params) =>{
  console.log(params);
  AuctionTradeComplementaryFilesNewDialogRef.value.openDialog(params, 'auction');

};
onMounted(() => {
    loadLand(1);
})

</script>
